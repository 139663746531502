{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx) ->
    $hiddenAttributes = $ctx.find('#hidden-attributes')
    url = $hiddenAttributes.data 'delete-url'
    gridId = $hiddenAttributes.data 'grid-id'
    $grid =$ctx.find("##{gridId}").staticgrid()[0]

    $grid.editor.on 'preRemove', ->
        uid = $grid.table.row('.selected').data().uid
        antiForgeryPost url, {uid}
        .then makeSuccessHandler $hiddenAttributes
        .fail makeErrorHandler $hiddenAttributes
        .always -> $grid.table.draw()

register 'App.MyRTKBase.Administration.LicenseAndBaseDeleteSetup.Setup', setup
