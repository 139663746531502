{getCorrectionFactorForLength} = require 'src/coffee/controls/map/utilities/coordinates'
{Plugin} = require 'src/coffee/controls/map/plugins/base'

WKT = require('ol/format/WKT').default
Feature = require('ol/Feature').default
Overlay = require('ol/Overlay').default
Circle = require('ol/geom/Circle').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

class MyRTKLiveViewTooltipPlugin extends Plugin
    constructor: ($ctx, layerName) ->
        @$ctx = $ctx
        @layerName = layerName

    init: ->
        @format = new WKT
        @radiusSource = new VectorSource
        @radiusVectorLayer = new VectorLayer
            map: @map
            source: @radiusSource
        @radiusVectorLayer.set 'name', 'radius-layer'
        @map.on 'pointermove', (evt) =>
            @radiusSource.clear()
            @map.forEachFeatureAtPixel evt.pixel, @showRadius

        @map.on 'singleclick', (evt) =>
            @overlay.setPosition(undefined)
            @map.forEachFeatureAtPixel evt.pixel, @machineInformationToolTip
        @setupOverlay()

    machineInformationToolTip: (feature, layer) =>
        return unless layer and layer.get('name') is @layerName
        @setContent feature.getProperties()
        pointGeometry = feature.getGeometry().getCoordinates()
        toolTipGeo = []
        toolTipGeo.push pointGeometry[0] + 824
        toolTipGeo.push pointGeometry[1] + 454

        @overlay.setPosition(pointGeometry)

        @$toolTipContainer.show()
        @showRadius feature


    showRadius: (feature, layer) =>
        return unless layer and layer.get('name') is @layerName

        @createRadiusOverlay feature

    createRadiusOverlay: (feature) ->
        featureProperties = feature.getProperties()
        console.log featureProperties.myRTKBasePosition
        unless featureProperties.myRTKBasePosition
            return
        if @radiusVectorLayer.getSource().getFeatureById featureProperties.userName
            return
        circleFeature = new Feature
            geometry: @createRadiusGeometry featureProperties
        circleFeature.setId featureProperties.userName
        @radiusSource.addFeature circleFeature

    createRadiusGeometry: (featureProperties) ->

        center = @format.readGeometry(featureProperties.myRTKBasePosition)
        center.transform 'EPSG:4326', 'EPSG:900913'
        radius = featureProperties.radius
        unless radius
            return
            #radius = $('#Radius').val()
        radius = radius * 1000 / getCorrectionFactorForLength(@map)
        return new Circle center.getCoordinates(), radius

    setContent: (toolTipData) ->
        status = toolTipData.status
        online = @$toolTipContainer.find('#online-status').data('status-online')
        offline = @$toolTipContainer.find('#online-status').data('status-offline')
        withinRange = @$toolTipContainer.find('#within-geofancing').data('within-range')
        outOfRange = @$toolTipContainer.find('#within-geofancing').data('out-of-range')
        noStatus = @$toolTipContainer.find('#online-status').data('no-status')
        noInformation = @$toolTipContainer.find('#within-geofancing').data('no-information')
        noGPS = @$toolTipContainer.find('#fix-point-float').data('no-gps')
        global = @$toolTipContainer.find('#within-geofancing').data('global')

        noPositionSet = @$toolTipContainer.find('#within-geofancing').data('no-position-set')
        fixPointFloat = @$toolTipContainer.find('#fix-point-float')
        withinGeoFancing = @$toolTipContainer.find('#within-geofancing')
        onlineStatus = @$toolTipContainer.find('#online-status')
        circleStatus = @$toolTipContainer.find('#online-status-circle')

        @$toolTipContainer.find('#vin').html(toolTipData.vin)
        @$toolTipContainer.find('#vehicle-name').html(toolTipData.vehicleName)
        @$toolTipContainer.find('#date').html(moment(toolTipData.date).format('L LT'))
        @$toolTipContainer.find('#number-of-satellites').html(toolTipData.numberOfSatellites)
        @$toolTipContainer.find('#altitude').html(toolTipData.altitude)
        @$toolTipContainer.find('#hdop').html(toolTipData.hdop)
        @$toolTipContainer.find('#title').html(toolTipData.userName)


        quality = toolTipData.quality
        if quality is 'RTKFix'
            fixPointFloat.html(quality)
        else if quality is 'DGPS'
            fixPointFloat.html(quality)
        else if quality is 'RTKFloat'
            fixPointFloat.html(quality)
        else if quality is 'GPS'
            fixPointFloat.html(quality)
        else
            fixPointFloat.html(noGPS)

        if status is 'Online'
            onlineStatus.html(online)

            circleStatus.removeClass('circle-red')
            circleStatus.addClass('circle-green')
            if toolTipData.myRTKBasePosition
                withinGeoFancing.html(withinRange)
            else
                if toolTipData.isGlobal
                    withinGeoFancing.html(global)
                else
                    withinGeoFancing.html(noPositionSet)
        else if status is 'Online_OutOfRange'
            onlineStatus.html(online)
            circleStatus.removeClass('circle-red')
            circleStatus.addClass('circle-green')
            if toolTipData.myRTKBasePosition
                withinGeoFancing.html(outOfRange)
            else
                if toolTipData.isGlobal
                    withinGeoFancing.html(global)
                else
                    withinGeoFancing.html(noPositionSet)
        else if status is 'Offline'
            onlineStatus.html(offline)
            circleStatus.removeClass('circle-green')
            circleStatus.addClass('circle-red')
            if toolTipData.myRTKBasePosition
                withinGeoFancing.html(withinRange)
            else
                if toolTipData.isGlobal
                    withinGeoFancing.html(global)
                else
                    withinGeoFancing.html(noPositionSet)
        else if status is 'Offline_OutOfRange'
            onlineStatus.html(offline)
            circleStatus.removeClass('circle-green')
            circleStatus.addClass('circle-red')
            if toolTipData.myRTKBasePosition
                withinGeoFancing.html(outOfRange)
            else
                if toolTipData.isGlobal
                    withinGeoFancing.html(global)
                else
                    withinGeoFancing.html(noPositionSet)
        else
            onlineStatus.html(noStatus)
            circleStatus.addClass('circle-grey')
            withinGeoFancing.html(noInformation)


    setupOverlay: ->
        @$toolTipContainer = @$ctx.find('#tooltip-container')
        @$toolTipContent = @$toolTipContainer.find('#tooltip-content')
        @$toolTipClose = @$toolTipContainer.find('#tooltip-close')
        @overlay = new Overlay
            element: @$toolTipContainer[0]

        @$toolTipClose.on 'click', =>
            @overlay.setPosition(undefined)
            @$toolTipClose.blur()
            return false

        @map.addOverlay @overlay

module.exports = {
    MyRTKLiveViewTooltipPlugin
}
