{post} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'
{Plugin} = require 'src/coffee/controls/map/plugins/base'

WKT = require('ol/format/WKT').default
Style = require('ol/style/Style').default
Stroke = require('ol/style/Stroke').default
Fill = require('ol/style/Fill').default
Text = require('ol/style/Text').default
Icon = require('ol/style/Icon').default
VectorSource = require('ol/source/Vector').default

customStyle = (feature, resolution) ->
    styleDefinition = {
        fill: 'rgba(255,255,255,0.3)'
        stroke: '#3794c1'
        label: true
        maxLabelResolution: 20}

    textStroke = new Stroke
        color: styleDefinition.stroke
        width: 3

    textFill = new Fill
        color: '#ffffff'

    displayLabel = feature.get('userName') and styleDefinition.label
    text = if not displayLabel then undefined else new Text
        textAlign: 'left'
        textBaseline: 'left'
        font: 'bold 12px Courier New'
        text: '' + (feature.get('userName') or '')
        fill: textFill
        stroke: textStroke
        offsetX: -20
        offsetY: 15
        rotation: 0

    properties = feature.getProperties()
    status = properties.status
    quality = properties.quality

    src = switch status
        when 'Online'
            switch quality
                when 'RTKFix' then '/images/pin-green-online.png'
                when 'RTKFloat' then '/images/pin-yellow-online.png'
                when 'DGPS' then '/images/pin-yellow-online.png'
                when 'GPS' then '/images/pin-orange-online.png'
                else '/images/pin-blue-online.png'
        when 'Offline'
            switch quality
                when 'RTKFix' then '/images/pin-green-offline.png'
                when 'RTKFloat' then '/images/pin-yellow-offline.png'
                when 'DGPS' then '/images/pin-yellow-offline.png'
                when 'GPS' then '/images/pin-orange-offline.png'
                else '/images/pin-blue-offline.png'
        when 'Online_OutOfRange' then '/images/pin-red-online.png'
        when 'Offline_OutOfRange' then '/images/pin-red-offline.png'
        else '/images/pin-blue-offline.png'

    return [
        new Style
            image: new Icon {anchor: [0.5, 1], src}
            text: text
    ]

class MyRTKLiveViewPlugin extends Plugin
    constructor: ($ctx, layerName, promise) ->
        @$ctx = $ctx
        @layerName = layerName
        @promise = promise

    init: ->
        @$updateButton = @$ctx.find 'button#update-positions'
            .on 'click', @refreshVehicles
        @$grid = @$ctx.find('#my-rtk-logins').staticgrid()[0]
        @refreshUrl = @$updateButton.data('url')
        @entityId = @$ctx.find('#EntityId').val()
        @showAdditionalOfflineMachines = @$ctx.find('#show-offline-machines').prop 'checked'
        @$toolTipContainer = @$ctx.find('#tooltip-container')
        online = @$toolTipContainer.find('#online-status').data('status-online')
        offline = @$toolTipContainer.find('#online-status').data('status-offline')
        noStatus = @$toolTipContainer.find('#online-status').data('no-status')
        @format = new WKT
        @vehicleFeatures = []
        @vehicleSource = new VectorSource
            features: @vehicleFeatures
        @vehicleLayer = @mapcontrol.layerManager.getLayersByName @layerName
        @vehicleLayer[0].setStyle customStyle
        @vehicleLayer[0].setSource @vehicleSource
        @promise.done =>
            @refreshVehicles()

        @$grid.table.on 'change.selection', (event) =>
            return if event.rows.length is 0
            feature = @vehicleLayer[0].getSource().getFeatureById event.rows[0].uid
            pointGeometry = feature.getGeometry().getCoordinates()
            view = @map.getView()
            toolTipGeo = []
            toolTipGeo.push pointGeometry[0] + 824
            toolTipGeo.push pointGeometry[1] + 454

            view = @map.getView()
            view.animate {zoom: 11}
            view.animate {center: toolTipGeo}

    refreshVehicles: =>
        view = @map.getView()
        geoCenter = view.getCenter()
        zoomLevel = view.getZoom()
        showAdditionalOfflineMachines = $('#show-offline-machines').prop 'checked'

        promise = post @refreshUrl, {@entityId, showAdditionalOfflineMachines, zoomLevel, geoCenter}
            .then @updateMap
            .then @$grid.table.order([[0,'desc'],[1,'asc']]).draw()
        waitscreen.waitFor promise

    updateMap: (model) =>
        rows = []
        unless not model? or model is ''
            rows = model.machineMapModels.map((x) => @$grid.converter.processJSObject(x))
        @$grid.table.clear()
        @$grid.table.rows.add(rows)
        @$grid.table.draw()
        @mapcontrol.clearMapServerLayer()
        defaultExtent = model.extent
        extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
        extent[0] = defaultExtent.xMin
        extent[1] = defaultExtent.yMin
        extent[2] = defaultExtent.xMax
        extent[3] = defaultExtent.yMax

        @mapcontrol.changeDefaultExtent extent
        @mapcontrol.reset()
        vehicles = model.machineMapModels
        console.log vehicles
        @vehicleFeatures = []
        @vehicleSource.clear()
        @addVehicles(vehicles)
        view = @map.getView()
        view.setCenter model.geoCenter
        view.setZoom model.zoomLevel

    updateFeatures: (vehicles) ->
        for vehicle in vehicles
            feature = @vehicleSource.getFeatureById(vehicle.uid)
            @updateFeature(feature, vehicle)

    addVehicles: (vehicles) ->
        for v in vehicles
            @addVehicle(v)

    updateFeature: (feature, vehicle) ->
        geo = @format.readGeometry vehicle.machinePosition
        geo.transform 'EPSG:4326', 'EPSG:900913'
        feature.setGeometry geo
        feature.setProperties
            date: vehicle.date
            status: vehicle.status
            quality: vehicle.quality
            numberOfSatellites: vehicle.numberOfSatellites
            altitude: vehicle.altitude
            hdop: vehicle.hdop

    addVehicle: (vehicle) ->
        feature = @format.readFeature vehicle.machinePosition
        console.log vehicle
        feature.setProperties
            date: vehicle.date
            uid: vehicle.uid
            status: vehicle.status
            quality: vehicle.quality
            radius: vehicle.radius
            myRTKBasePosition: vehicle.myRTKBasePosition
            numberOfSatellites: vehicle.numberOfSatellites
            altitude: vehicle.altitude
            hdop: vehicle.hdop
            vin: vehicle.vin
            userName: vehicle.userName
            vehicleName: vehicle.vehicleName

        feature.setId vehicle.uid
        feature.getGeometry().transform 'EPSG:4326', 'EPSG:900913'

        @vehicleFeatures.push feature
        @vehicleSource.addFeature feature

module.exports = {
    MyRTKLiveViewPlugin
}
