{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

setup = ($ctx) ->
    $renewalDropdown = $ctx.find('#runtime-dropdown')
    $question = $ctx.find('#question')
    $modal = $ctx.find('#renew-license-modal')
    $renewLicense = $ctx.find('#renew-license')
    $hiddenAttributes = $ctx.find('#hidden-attributes')
    $modal.on 'shown.bs.modal', ->
        question = $question.data('question').replace('{Date}', $renewalDropdown.text())
        $question.text question

        $renewLicense.on 'click', ->
            $modal.modal('hide')
            url = $renewLicense.data 'url'
            data =
                entityId: $hiddenAttributes.data 'entity-id'
                loginId: $hiddenAttributes.data 'uid'
                months: $renewalDropdown.val()

            promise = antiForgeryPost url, data
                .then makeRefreshHandler()
                .done makeSuccessHandler $renewLicense
                .fail makeErrorHandler $renewLicense

            waitscreen.waitFor promise

register 'App.MyRTKBase.Administration.RenewLicense.Setup', setup
