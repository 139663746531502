{register} = require 'src/js/infrastructure/setup-registry'
{getByName} = require 'src/coffee/controls/map'

{MyRTKLiveViewPlugin} = require 'src/coffee/areas/my-rtk-base/evaluation/plugins/live-view-plugin'
{MyRTKLiveViewTooltipPlugin} = require 'src/coffee/areas/my-rtk-base/evaluation/plugins/live-view-tooltip-plugin'

setup = ($ctx, promise) ->
    layerName = $ctx.find('#update-positions').data 'layer-name'
    map = getByName 'live-view', $ctx
    map.registerPlugin new MyRTKLiveViewPlugin($ctx, layerName, promise)
    map.registerPlugin new MyRTKLiveViewTooltipPlugin($ctx, layerName)

register 'App.MyRTKBase.Evaluation.LiveView.Setup', setup