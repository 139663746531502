{post} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'
{Plugin} = require 'src/coffee/controls/map/plugins/base'

WKT = require('ol/format/WKT').default
Style = require('ol/style/Style').default
Stroke = require('ol/style/Stroke').default
Fill = require('ol/style/Fill').default
Text = require('ol/style/Text').default
Icon = require('ol/style/Icon').default
VectorSource = require('ol/source/Vector').default

customStyle = (feature, resolution) ->
    styleDefinition = {
        fill: 'rgba(255,255,255,0.3)'
        stroke: '#3794c1'
        label: true
        maxLabelResolution: 20}

    textStroke = new Stroke
        color: styleDefinition.stroke
        width: 3

    textFill = new Fill
        color: '#ffffff'

    displayLabel = feature.get('stationName') and styleDefinition.label
    text = if not displayLabel then undefined else new Text
        textAlign: 'left'
        textBaseline: 'left'
        font: 'bold 12px Courier New'
        text: '' + (feature.get('stationName') or '')
        fill: textFill
        stroke: textStroke
        offsetX: -20
        offsetY: 15
        rotation: 0

    properties = feature.getProperties()
    status = properties.statusValue

    src = switch status
        when 0 then '/images/pin-green-online.png'
        when 1 then '/images/pin-red-offline.png'
        when 2 then '/images/pin-yellow-online.png'
        when 3 then '/images/pin-orange-online.png'
        else '/images/pin-blue-online.png'

    return [
        new Style
            image: new Icon {anchor: [0.5, 1], src}
            text: text
    ]

class MyRTKBasesLiveViewPlugin extends Plugin
    constructor: ($ctx, layerName, promise) ->
        @$ctx = $ctx
        @layerName = layerName
        @promise = promise

    init: ->
        @$updateButton = @$ctx.find 'button#update-positions'
            .on 'click', @refreshVehicles
        @$grid = @$ctx.find('#bases').staticgrid()[0]
        @refreshUrl = @$updateButton.data('url')
        @entityId = @$ctx.find('#EntityId').val()
        @showAdditionalOfflineMachines = @$ctx.find('#show-offline-bases').prop 'checked'
        @$toolTipContainer = @$ctx.find('#tooltip-container')
        @format = new WKT
        @vehicleFeatures = []
        @vehicleSource = new VectorSource
            features: @vehicleFeatures
        @vehicleLayer = @mapcontrol.layerManager.getLayersByName @layerName
        @vehicleLayer[0].setStyle customStyle
        @vehicleLayer[0].setSource @vehicleSource
        @promise.done =>
            @refreshVehicles()

        @$grid.table.on 'change.selection', (event) =>
            return if event.rows.length is 0
            feature = @vehicleLayer[0].getSource().getFeatureById event.rows[0].uid
            pointGeometry = feature.getGeometry().getCoordinates()
            view = @map.getView()
            toolTipGeo = []
            toolTipGeo.push pointGeometry[0] + 824
            toolTipGeo.push pointGeometry[1] + 454

            view = @map.getView()
            view.animate {zoom: 9}
            view.animate {center: toolTipGeo}

    refreshVehicles: =>
        view = @map.getView()
        geoCenter = view.getCenter()
        zoomLevel = view.getZoom()
        promise = post @refreshUrl, {@entityId, zoomLevel, geoCenter}
            .then @updateMap
            .then @$grid.table.order([[0, 'desc'], [1, 'asc']]).draw()
        waitscreen.waitFor promise

    updateMap: (model) =>
        rows = []
        unless not model? or model is ''
            rows = model.baseMapModels.map((x) => @$grid.converter.processJSObject(x))
        @$grid.table.clear()
        @$grid.table.rows.add(rows)
        @$grid.table.draw()
        @mapcontrol.clearMapServerLayer()
        defaultExtent = model.extent
        extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
        extent[0] = defaultExtent.xMin
        extent[1] = defaultExtent.yMin
        extent[2] = defaultExtent.xMax
        extent[3] = defaultExtent.yMax

        @mapcontrol.changeDefaultExtent extent
        @mapcontrol.reset()
        vehicles = model.baseMapModels
        @vehicleFeatures = []
        @vehicleSource.clear()
        @addVehicles(vehicles)
        view = @map.getView()
        view.setCenter model.geoCenter
        view.setZoom model.zoomLevel

    updateFeatures: (vehicles) ->
        for vehicle in vehicles
            feature = @vehicleSource.getFeatureById(vehicle.uid)
            @updateFeature(feature, vehicle)

    addVehicles: (vehicles) ->
        for v in vehicles
            @addVehicle(v)

    updateFeature: (feature, vehicle) ->
        geo = @format.readGeometry vehicle.position
        geo.transform 'EPSG:4326', 'EPSG:900913'
        feature.setGeometry geo
        feature.setProperties
            date: vehicle.lastUsed
            status: vehicle.status
            rtcmStatus: vehicle.rtcmStatus
            cmrStatus: vehicle.cmrStatus
            altitude: vehicle.altitude
            stationName: vehicle.stationName
            positionString: vehicle.positionString
            position: vehicle.position

    addVehicle: (vehicle) ->
        feature = @format.readFeature vehicle.position

        feature.setProperties
            date: vehicle.lastUsed
            uid: vehicle.uid
            status: vehicle.status
            statusValue: vehicle.statusValue
            radius: vehicle.radius
            position: vehicle.position
            altitude: vehicle.altitude
            rtcmStatus: vehicle.rtcmStatus
            cmrStatus: vehicle.cmrStatus
            stationName: vehicle.stationName
            positionString: vehicle.positionString
            orgUnitName: vehicle.orgUnitName

        feature.setId vehicle.uid
        feature.getGeometry().transform 'EPSG:4326', 'EPSG:900913'

        @vehicleFeatures.push feature
        @vehicleSource.addFeature feature

module.exports = {
    MyRTKBasesLiveViewPlugin
}
