{register} = require 'src/js/infrastructure/setup-registry'
{RadiusPlugin} = require 'src/coffee/areas/rtk/radius-map-plugin'
{getByName} = require 'src/coffee/controls/map'
{LocationSearchPlugin} = require 'src/coffee/controls/map/plugins/location-search'

setup = ($ctx) ->
    map = getByName 'base-position', $ctx
    map.registerPlugin new LocationSearchPlugin($ctx, false)
    #this has to be the last plug in that is added to the map, since we get the plugin at update map
    layer = map.layerManager.layers.webgis()[0] or map.layerManager.layers.vector()[0]
    map.registerPlugin new RadiusPlugin layer
    map.layerManager.layers.webgis()[0].getSource().on 'changefeature', updateLatLon
    map.layerManager.layers.webgis()[0].getSource().on 'addfeature', updateLatLon
    map.layerManager.layers.webgis()[0].getSource().on 'removefeature', clearLatLon
    $updateBtn = $ctx.find('#update-position')
    $updateBtn.on 'click', updatePosition
    $lon = $ctx.find('#longitude')
    $lat = $ctx.find('#latitude')
    $rad = $ctx.find('#radius')
    $lon.on 'input', ->
        if $lon.val().length is 0
            $updateBtn.disable()
        else
            $updateBtn.enable()
    $lat.on 'input', ->
        if $lat.val().length is 0
            $updateBtn.disable()
        else
            $updateBtn.enable()
    $rad.on 'input', ->
        if $rad.val().length is 0
            $updateBtn.disable()
        else
            $updateBtn.enable()
clearLatLon = ->
    $('#longitude').val("")
    $('#latitude').val("")

updateLatLon = (evt) ->
    center = evt.feature.getGeometry().clone()
    center.transform('EPSG:900913', 'EPSG:4326')
    coords = center.getCoordinates()
    $('#longitude').val(Globalize.formatNumber(coords[0],{minimumFractionDigits:15}))
    $('#latitude').val(Globalize.formatNumber(coords[1],{minimumFractionDigits:15}))

updatePosition = ->
    radius = $('#radius').val()
    parser = Globalize.numberParser()
    longitude = parser($('#longitude').val())
    latitude = parser($('#latitude').val())

    map = getByName 'base-position'
    unless map
        return
    features = map.layerManager.layers.webgis()[0].getSource().getFeatures()
    unless features.length isnt 0
        return
    geo = features[0].getGeometry()
    geo.setCoordinates([longitude, latitude])
    geo.transform('EPSG:4326', 'EPSG:900913')

    features[0].set 'radius', radius
    radiusPlugin = map.plugins[map.plugins.length - 1]
    radiusPlugin.pointMoved
        feature: features[0]
    view = map.map.getView()
    view.setCenter(geo.getCoordinates())
    extent = geo.getExtent()
    parsedRadius = parser(radius)
    extent[0] = extent[0] - parsedRadius * 1000
    extent[1] = extent[1] - parsedRadius * 1000
    extent[2] = extent[2] + parsedRadius * 1000
    extent[3] = extent[3] + parsedRadius * 1000
    map.changeDefaultExtent extent
register 'App.MyRTKBase.BasePosition.Setup', setup
