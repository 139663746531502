{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx) ->
    $updateLicense = $ctx.find('#update-license')
    $newLicenceBegin = $ctx.find('#new-license-begin')
    $newLicenceBeginPicker = $newLicenceBegin.data('DateTimePicker')
    $newLicenceEnd = $ctx.find('#new-license-end')
    $newLicenceEndPicker = $newLicenceEnd.data('DateTimePicker')
    $licenceBeginPicker = $ctx.find('#license-begin').data('DateTimePicker')
    $licenceEndPicker = $ctx.find('#license-end').data('DateTimePicker')
    $modal = $ctx.find('#set-new-start-date-modal')
    startDate = $licenceBeginPicker.date()
    endDate = $licenceEndPicker.date()
    $newLicenceBeginPicker.date(startDate)
    $newLicenceEndPicker.date(endDate)
    $hiddenAttributes = $ctx.find('#hidden-attributes')

    year = 1
    runtime = 12

    if startDate && endDate
        newStartDate = startDate.clone()
        newEndDate = endDate.clone()
        year = newEndDate.year() - newStartDate.year()
        runtime = newEndDate.month() - newStartDate.month() + year * 12

    $newLicenceBegin.on 'dp.change', ->
        selectedDate = $newLicenceBeginPicker.date()
        if selectedDate
            $newLicenceEndPicker.date selectedDate.add(runtime, 'months')
            $updateLicense.enable()
        else
            $updateLicense.disable()

    $updateLicense.on 'click', ->
        url = $updateLicense.data 'url'
        data =
            loginId: $hiddenAttributes.data 'uid'
            newStartDate: $newLicenceBeginPicker.date()
            newEndDate: $newLicenceEndPicker.date()
        $modal.modal('hide')
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $updateLicense
            .fail makeErrorHandler $updateLicense
        waitscreen.waitFor promise

register 'App.MyRTKBase.Administration.UpdateStartDate.Setup', setup
