{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{notifications, waitscreen} = require 'src/js/infrastructure'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

setup = ($ctx) ->
    $save = $ctx.find('#save')

    uid = $ctx.find('#UID').val()
    $grid = $ctx.find('#licenses').staticgrid()[0]
    $grid.table.columns(1,0).order('desc').draw()
    $form = $ctx.find('#form')
    $save.on 'click', ->
        $('.nav.nav-tabs > li a[href="#generalsHref"]').tab('show')
        generalsValid = $form.valid()
        unless generalsValid
            return false

        $('.nav.nav-tabs > li a[href="#mapHref"]').tab('show')
        mapValid = $form.valid()
        unless mapValid
            return false

        url = $save.data 'url'
        data =
            uid: uid
            name: $('#name').val()
            fkEntity: $('#org-units').val()
            isActive: $('#is-active').prop('checked')
            ip: $('#ip').val()
            portCMR: $('#port-cmr').val()
            portRTCM: $('#port-rtcm').val()
            enableRTCM: $('#enable-rtcm').prop('checked')
            enableCMR: $('#enable-cmr').prop('checked')
            reconnectRTCM: $('#reconnect-rtcm').prop('checked')
            reconnectCMR: $('#reconnect-cmr').prop('checked')
            altitude: $('#altitude').val()
            allowFallback: $('#allow-fallback').prop('checked')
            mountpointRTCM: $('#mountpoint-rtcm').val()
            usernameRTCM: $('#username-rtcm').val()
            passwordRTCM: $('#password-rtcm').val()
            mountpointCMR: $('#mountpoint-cmr').val()
            usernameCMR: $('#username-cmr').val()
            passwordCMR: $('#password-cmr').val()
            externalEmailAddresses: $('#external-email-addresses').val()
            internalEmailAddresses: $('#internal-email-addresses').val()
            note: $('#note').val()
            longitude: $('#longitude').val()
            latitude: $('#latitude').val()
            radius: $('#radius').val()
            changedLicenses: $grid.state.changes()
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $save
            .fail (jqXHR, textStatus, errorThrown) ->
                if jqXHR.responseJSON?.message
                    notifications.error(jqXHR.responseJSON.message)
                else
                    makeErrorHandler $save

        waitscreen.waitFor promise

register 'App.MyRTKBase.CreateOrEditBases.Setup', setup
