{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen, notifications} = require 'src/js/infrastructure'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
setup = ($ctx) ->
    $runtimeDropdown = $ctx.find('#runtime-dropdown')
    months = parseInt($runtimeDropdown.val())
    $startDateTime = $ctx.find('#license-begin')
    $startDateTimePicker = $startDateTime.data('DateTimePicker')
    $endDateTimePicker = $ctx.find('#license-end').data('DateTimePicker')
    $form = $ctx.find('#form')
    $hiddenAttributes = $ctx.find('#hidden-attributes')

    $grid = $ctx.find('#assigned-bases').staticgrid()[0]
    $grid.table.columns(1,0).order('desc').draw()
    $saveButton = $ctx.find('#save')
    licensingPeriodSelect = $runtimeDropdown.selectize()
    licensingPeriodSelectize = licensingPeriodSelect[0].selectize
    endDate = $endDateTimePicker.date()
    $setNewStartDate = $ctx.find('#set-new-start-date')
    runtimeSelect = $runtimeDropdown.selectize()
    runtimeSelectize = runtimeSelect[0].selectize
    $startDateTime.on 'dp.change', ->
        endDateClone = endDate.clone()
        months = $runtimeDropdown.val()

        unless $startDateTimePicker.date()
            $endDateTimePicker.date endDateClone
            return
        unless months
            return
        newEndDate = $startDateTimePicker.date().add(months, 'month')
        $endDateTimePicker.date newEndDate

    $runtimeDropdown.on 'change', ->
        endDateClone = endDate.clone()

        unless $runtimeDropdown.val()
            $endDateTimePicker.date(endDateClone)
            $saveButton.parent().popover('destroy')
            return
        months = $runtimeDropdown.val()
        if $hiddenAttributes.data 'uid'
            $saveButton.parent().popover()
            newDate = endDateClone.add(months, 'months')
            $endDateTimePicker.date(newDate)
            return
        startDateClone = $startDateTimePicker.date().clone()
        newDate = startDateClone.add(months, 'months')
        $endDateTimePicker.date(newDate)

    $saveButton.on 'click', ->
        $('.nav.nav-tabs > li a[href="#generalsHref"]').tab('show')
        unless $form.valid()
            return
        url = $saveButton.data 'url'
        data =
            UID: $hiddenAttributes.data 'uid'
            fkEntity: $('#entities').val()
            fKVehicle: $('#vehicles').val()
            username: $('#username').val()
            password: $('#password').val()

            allowiXmapFill: $('#ixmap-fill').prop('checked')
            mountpoint: $('#mount-point').val()
            note: $('#note').val()
            port: $('#port').val()
            serverAddress: $('#server-address').val()
            changedBases: $grid.state.changes()
        unless $hiddenAttributes.data('uid')
            data.licenseBegin = $startDateTimePicker.date()
            data.licenseEnd = $endDateTimePicker.date()
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $saveButton
            .fail (jqXHR, textStatus, errorThrown) ->
                if jqXHR.responseJSON?.message
                    notifications.error(jqXHR.responseJSON.message)
                else
                    makeErrorHandler $saveButton

        waitscreen.waitFor promise

    setCurrentRuntime = ($ctx) ->
        if $hiddenAttributes.data 'uid'
            $currentRuntime = $ctx.find('#current-runtime')
            currentRunTimeText = $currentRuntime.data 'text'
            currentRuntimeYears = $endDateTimePicker.date().year() - $startDateTimePicker.date().year()
            currentRuntimeMonths = $endDateTimePicker.date().month() - $startDateTimePicker.date().month()
            currentRunTimeText = currentRunTimeText.replace('{years}', currentRuntimeYears).replace('{months}', currentRuntimeMonths)
            $currentRuntime.val(currentRunTimeText)
    eventsHandler = ($ctx) ->
        if $hiddenAttributes.data 'uid'
            $openModalRenewLicense = $ctx.find('#open-modal-renew-license')
            $reactivateLicense = $ctx.find('#reactivate-license')

            $runtimeDropdown.on 'change', ->
                unless $runtimeDropdown.val()
                    $openModalRenewLicense.disable()
                    return
                $openModalRenewLicense.enable()
            if moment().isAfter($endDateTimePicker.date())
                runtimeSelectize.disable()
                $setNewStartDate.disable()
                $reactivateLicense.enable()
            else
                runtimeSelectize.enable()
                $setNewStartDate.enable()
                $reactivateLicense.disable()
    eventsHandler $ctx
    setCurrentRuntime $ctx


register 'App.MyRTKBase.Administration.CreateOrEditLogin.Setup', setup