{getCorrectionFactorForLength} = require 'src/coffee/controls/map/utilities/coordinates'
{Plugin} = require 'src/coffee/controls/map/plugins/base'

WKT = require('ol/format/WKT').default
Feature = require('ol/Feature').default
Overlay = require('ol/Overlay').default
Circle = require('ol/geom/Circle').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

class MyRTKBasesLiveViewTooltipPlugin extends Plugin
    constructor: ($ctx, layerName) ->
        @$ctx = $ctx
        @layerName = layerName

    init: ->
        @format = new WKT
        @radiusSource = new VectorSource
        @radiusVectorLayer = new VectorLayer
            map: @map
            source: @radiusSource
        @radiusVectorLayer.set 'name', 'radius-layer'
        @map.on 'pointermove', (evt) =>
            @radiusSource.clear()
            @map.forEachFeatureAtPixel evt.pixel, @showRadius

        @map.on 'singleclick', (evt) =>
            @overlay.setPosition(undefined)
            @map.forEachFeatureAtPixel evt.pixel, @machineInformationToolTip
        @setupOverlay()

    machineInformationToolTip: (feature, layer) =>
        return unless layer and layer.get('name') is @layerName
        @setContent feature.getProperties()
        pointGeometry = feature.getGeometry().getCoordinates()
        toolTipGeo = []
        toolTipGeo.push pointGeometry[0] + 824
        toolTipGeo.push pointGeometry[1] + 454

        @overlay.setPosition(pointGeometry)

        @$toolTipContainer.show()
        @showRadius feature


    showRadius: (feature, layer) =>
        console.log feature
        console.log layer
        return unless layer and layer.get('name') is @layerName

        @createRadiusOverlay feature

    createRadiusOverlay: (feature) ->
        featureProperties = feature.getProperties()

        unless featureProperties.position
            return
        if @radiusVectorLayer.getSource().getFeatureById featureProperties.stationName
            return
        circleFeature = new Feature
            geometry: @createRadiusGeometry featureProperties
        circleFeature.setId featureProperties.stationName
        @radiusSource.addFeature circleFeature

    createRadiusGeometry: (featureProperties) ->

        center = @format.readGeometry(featureProperties.position)
        center.transform 'EPSG:4326', 'EPSG:900913'
        radius = featureProperties.radius
        unless radius
            return
            #radius = $('#Radius').val()
        radius = radius * 1000 / getCorrectionFactorForLength(@map)
        return new Circle center.getCoordinates(), radius

    setContent: (toolTipData) ->
        @$toolTipContainer.find('#status').html(toolTipData.status)
        @$toolTipContainer.find('#last-used').html(moment(toolTipData.date).format('L LT'))
        @$toolTipContainer.find('#altitude').html(toolTipData.altitude)
        @$toolTipContainer.find('#position').html(toolTipData.positionString)
        @$toolTipContainer.find('#radius').html(toolTipData.radius)
        @$toolTipContainer.find('#rtcm-status').html(toolTipData.rtcmStatus)
        @$toolTipContainer.find('#cmr-status').html(toolTipData.cmrStatus)
        @$toolTipContainer.find('#org-unit-name').html(toolTipData.orgUnitName)
        @$toolTipContainer.find('#title').html(toolTipData.stationName)

    setupOverlay: ->
        @$toolTipContainer = @$ctx.find('#tooltip-container')
        @$toolTipContent = @$toolTipContainer.find('#tooltip-content')
        @$toolTipClose = @$toolTipContainer.find('#tooltip-close')
        @overlay = new Overlay
            element: @$toolTipContainer[0]

        @$toolTipClose.on 'click', =>
            @overlay.setPosition(undefined)
            @$toolTipClose.blur()
            return false

        @map.addOverlay @overlay

module.exports = {
    MyRTKBasesLiveViewTooltipPlugin
}
