{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx) ->
    $reactivateLicense = $ctx.find('#reactivate')

    $newLicenceBegin = $ctx.find('#reactivate-license-begin')
    $newLicenceBeginPicker = $newLicenceBegin.data('DateTimePicker')
    $newLicenceEnd = $ctx.find('#reactivate-license-end')
    $newLicenceEndPicker = $newLicenceEnd.data('DateTimePicker')

    $runtimeDropdown = $ctx.find('#reactivate-runtime-dropdown')
    $hiddenAttributes = $ctx.find('#hidden-attributes')
    $modal = $ctx.find('#reactivate-license-modal')
    endDateClone = $newLicenceEndPicker.date().clone()
    $newLicenceBegin.on 'dp.change', ->
        selectedDate = $newLicenceBeginPicker.date()
        if selectedDate
            runtime = $runtimeDropdown.val()
            $newLicenceEndPicker.date selectedDate.add(runtime, 'months')
            $reactivateLicense.enable()
        else
            $newLicenceEndPicker.date endDateClone
            $reactivateLicense.disable()

    $runtimeDropdown.on 'change', ->
        runtime = $runtimeDropdown.val()
        unless runtime
            $newLicenceEndPicker.date endDateClone
            return
        $newLicenceEndPicker.date($newLicenceBeginPicker.date().add(runtime, 'months'))

    $reactivateLicense.on 'click', ->
        url = $reactivateLicense.data 'url'
        data =
            loginId: $hiddenAttributes.data 'uid'
            newStartDate: $newLicenceBeginPicker.date()
            newEndDate: $newLicenceEndPicker.date()
        $modal.modal('hide')
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $reactivateLicense
            .fail makeErrorHandler $reactivateLicense
        waitscreen.waitFor promise

register 'App.MyRTKBase.Administration.ReactivateLicense.Setup', setup
