{register} = require 'src/js/infrastructure/setup-registry'
{post, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{waitscreen} = require 'src/js/infrastructure'
setup = ($ctx) ->
    $detailsModal = $ctx.find('#utilization-details-modal')
    $grid = $ctx.find('#stations').staticgrid()[0]
    $duration = $ctx.find('#duration')
    $detailsGrid = $ctx.find('#utilization-details-grid').staticgrid()[0]
    allRows = []
    $iXmapFillCheckBox = $ctx.find('#show-only-ixmap-fill')
    $utilizationParameter = $ctx.find('#utilization-parameters')
    $modalTitle = $ctx.find('#utilization-details-modal h4.modal-title')

    $startDatePicker = $ctx.find('#start-date-picker')
    $startDatePicker.datetimepicker()
    $updateBtn = $ctx.find('#update-details')

    $endDatePicker = $ctx.find('#end-date-picker')
    $endDatePicker.datetimepicker
    # useCurrent : false #Important! See issue #1075

    startDate = $startDatePicker.data('DateTimePicker').date()
    endDate = $endDatePicker.data('DateTimePicker').date()

    # set today as the maximum for both dates
    $startDatePicker.data('DateTimePicker').maxDate(endDate)
    $endDatePicker.data('DateTimePicker').maxDate(endDate).minDate(startDate)

    $updateBtn.on 'click', ->
        startDate = $startDatePicker.data('DateTimePicker').date()
        endDate = $endDatePicker.data('DateTimePicker').date()
        unless startDate is null or endDate is null
            getUtilization()

    $startDatePicker.on 'dp.change', (e) ->
        $endDatePicker.data('DateTimePicker').maxDate(moment()).minDate(e.date)

    $endDatePicker.on 'dp.change', (e) ->
        $startDatePicker.data('DateTimePicker').maxDate(e.date)
        $endDatePicker.data('DateTimePicker').maxDate(moment())

    $ctx.find('#csv-export-button').on 'click', (event) ->
        $btn = $(event.currentTarget)
        promise = post $btn.data 'url'
            .done (url) -> window.location = url
            .fail makeErrorHandler $btn

            waitscreen.waitFor promise

    selectedLicenseChanged = (event) ->
        $utilizationButton = $('#show-utilization-button')

        if event.rows.length > 1
            $grid.editControls.edit.$button.disable()
        else
            $grid.editControls.edit.$button.enable()

        if event.rows.length > 0
            $utilizationButton.parent().popover('destroy')
            $utilizationButton.enable()
        else
            $utilizationButton.parent().popover('enable')
            $utilizationButton.disable()

    refreshDetailsModal = ->
        selectedStationNames = $grid.state.rows('.selected').map( (x) => x.name)

        $modalTitle.text selectedStationNames.sort().toString().split(',').join(' - ')
        getUtilization()

    getUtilization =  ->
        $duration.val null

        url = $utilizationParameter.data 'url'
        data =
            stationNames: $grid.state.rows('.selected').map( (x) => x.name)
            startDate: $startDatePicker.data('DateTimePicker').date()
            endDate: $endDatePicker.data('DateTimePicker').date()

        promise = antiForgeryPost url, data
            .done (utilization) ->
                refreshUtilizationDetailsGrid utilization
            .fail makeErrorHandler $utilizationParameter

        waitscreen.waitFor promise

    refreshUtilizationDetailsGrid = (utilization) ->

        $detailsGrid.table.rows().deselect()
        $detailsGrid.table.rows().remove()

        for row in utilization.rows
            row.logon = moment row.logon
            row.logoff = if row.logoff then moment row.logoff else null

        $detailsGrid.table.rows.add(utilization.rows).draw()
        $detailsGrid.table.columns.adjust()
        allRows = $detailsGrid.table.rows().data().toArray()
        $iXmapFillCheckBox.prop('checked', false)

        $duration.val utilization.duration

    $iXmapFillCheckBox. on 'change', ->
        url = $iXmapFillCheckBox.data('duration-url')
        if $iXmapFillCheckBox.prop('checked')
            rows = allRows.filter (x) -> x.iXmapFill is true
            durations = rows.map (x) -> x.duration.toString()
            post url, {durations}
            .done (duration) ->
                $duration.val(duration)
            $detailsGrid.table.rows().remove()
            $detailsGrid.table.rows.add(rows).draw()
            $detailsGrid.table.columns.adjust()
        else
            durations = allRows.map (x) -> x.duration
            post url, {durations}
            .done (duration) ->
                $duration.val(duration)
            $detailsGrid.table.rows().remove()
            $detailsGrid.table.rows.add(allRows).draw()
            $detailsGrid.table.columns.adjust()
    $grid.table.on 'change.selection', selectedLicenseChanged
    $detailsModal.on 'shown.bs.modal', refreshDetailsModal

register 'App.MyRTKBase.Administration.BasesUtilization.Setup', setup
